import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider, Link, Navigate, useNavigate } from "react-router-dom";
import CreateServiceRequest from "./components/CreateServiceRequest";

import { MenuFoldOutlined, MenuUnfoldOutlined, HomeOutlined, UserOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { Layout, Menu, theme, Button, ConfigProvider, Space, Row, Col } from "antd";
import EgluLogo from "./components/Logo";
import Login from "./components/Login";
import SignUp from "./components/SignUp";
import Dashboard from "./components/dashboard";
import axios from "axios";
import ForgotPassword from "./components/ForgotPassword";
const { Header, Content, Footer, Sider } = Layout;

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["token"] = token;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      localStorage.removeItem("token");
      localStorage.setItem("setupModeEnabled", "false");
      window.location.href = "/";
    }
    return response;
  },
  (err) => {
    if (err.response && err.response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }
    return err.response;
  }
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/service-request",
    element: <CreateServiceRequest />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/signup",
    element: <SignUp />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/forgotpassword",
    element: <ForgotPassword />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
//const [collapsed, setCollapsed] = useState(true);
const { defaultAlgorithm, darkAlgorithm } = theme;

root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        algorithm: darkAlgorithm,
        token: {
          colorText: "#ae9572",
        },
        components: {
          Checkbox: {
            colorPrimary: "#ae9572",
            colorBgBase: "#ae9572",
          },
          Steps: {
            colorTextLabel: "#ae9572",
            colorTextDescription: "#ae9752",
            colorPrimary: "#ae9752",
            // colorSplit: "#ae9752",
            colorFillContent: "#ae9752",
            colorTextLabel: "#000000",
            //controlItemBgActive: "#ae9752",
            //colorIng: "#000000",
          },
          Button: {
            colorPrimary: "#ae9572",
          },
        },
      }}
    >
      <Layout className="mainLayout">
        <Header
          style={{
            padding: 0,
            backgroundColor: "transparent",
          }}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={8}>
              <EgluLogo
                style={{ width: 100, height: 50, marginLeft: 30, marginTop: 10, cusor: "pointer" }}
                color="#ae9572"
                onClick={() => {
                  window.location.href = "/";
                }}
              />
            </Col>
          </Row>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 700,
            //background: "white",
          }}
        >
          <RouterProvider router={router} />
        </Content>
        <Footer style={{ textAlign: "center", backgroundColor: "transparent" }}>
          &#169; {new Date().getFullYear()}{" "}
          <a href="https://myeglu.com" target="_blank" style={{ color: "#ae9572", textDecoration: "underline" }}>
            WiZN Systems Private Limited
          </a>{" "}
          | All Rights Reserved
        </Footer>
      </Layout>
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
