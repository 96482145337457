import { Button, Checkbox, Col, Card, Form, Input, Row, Collapse, Select, notification, Modal, Table, Space, Alert, Radio, Search } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined, SettingOutlined, ArrowLeftOutlined, UserOutlined, VideoCameraOutlined, PlusCircleOutlined } from "@ant-design/icons";
//import { Content } from "antd/es/layout/layout";
import Address from "./Address";
import React, { Fragment, useState, useRef, useEffect } from "react";
import Devices from "./Devices";
import axios from "axios";
import { API_ENDPOINT, CAPTCHA_KEY } from "../api/endPoint";
import { Layout, Menu, theme } from "antd";
import { useNavigate } from "react-router-dom";
import { SAVED_B_ADDRESS_KEY, SAVED_S_ADDRESS_KEY, isValidEmail } from "../utils/constants";
import { v4 as uuidv4 } from "uuid";
import hash from "object-hash";
import ContactNotifier from "./ContactNotifier";
import ReCAPTCHA from "react-google-recaptcha";
import { useToken } from "antd/es/theme/internal";
import AdditionalCharges from "./AdditioanalCharges";
import { Descriptions } from "antd";
import { validateAddress } from "../utils/methods";

const { Header, Content, Footer, Sider } = Layout;

const { Panel } = Collapse;

const CreateServiceRequest = (props) => {
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
      if (selectedRows.length > 0) {
        selectedBAddress = selectedRows[0].initValue;
      }
    },
  };
  const rowSelectionSAddress = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
      if (selectedRows.length > 0) {
        selectedSAddress = selectedRows[0].initValue;
      }
    },
  };
  const addressColumns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Attentiion",
      dataIndex: "attention",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "Address",
      dataIndex: "address",
    },
  ];

  function onAddressChange(address) {
    setServiceRequest({ ...serviceRequest, bAddress: address });
  }

  function onAddNewUserAddressChange(address) {
    setNewUser({ ...newUser, bAddress: address });
  }

  function onAddNewUserSAddressChange(address) {
    setNewUser({ ...newUser, sAddress: address });
  }

  function onSAddressChange(address) {
    setServiceRequest({ ...serviceRequest, sAddress: address });
  }

  const getAllUsers = async () => {
    try {
      let response = await axios.get(API_ENDPOINT + "/users/all");
      if (response.data.users) {
        setUsers(response.data.users);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAllSRUsers = async () => {
    try {
      let response = await axios.get(API_ENDPOINT + "/users/srUsers");
      if (response.data.users) {
        setSRUsers(response.data.users);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchGST = async () => {
    try {
      setisFetchingGSTIN(true);
      let response = await axios.get(API_ENDPOINT + "/users/fetchGST/" + fetchGSTNo);
      setisFetchingGSTIN(false);
      if (response.data.success) {
        let bAddress = {};
        let data = response.data.gstInfo;
        let addr = data.pradr?.addr;
        bAddress.name = data.tradeNam;
        bAddress.companyName = data.tradeNam;
        bAddress = {
          ...bAddress,
          attention: data.tradeNam,
          state: addr.stcd,
          zip: addr.pncd,
          addressLine1: addr.bnm,
          addressLine2: addr.st,
          GSTIN: fetchGSTNo,
          country: "India",
          city: addr.city == "" ? addr.dst : addr.city,
        };
        setNewUser({ bAddress, sAddress: {} });
      }
    } catch (err) {
      setisFetchingGSTIN(false);
      setErrorText(err.response.data.message);
      setTimeout(() => {
        setErrorText(undefined);
      }, 2000);
    }
  };

  const getUserDetails = async () => {
    try {
      let response = await axios.get(API_ENDPOINT + "/users/detail");
      if (response.data.user) {
        if (response.data.user.bAddress && response.data.user.sAddress) {
          setServiceRequest({ ...serviceRequest, bAddress: response.data.user.bAddress, sAddress: response.data.user.sAddress });
        } else if (response.data.user.sAddress) {
          setServiceRequest({ ...serviceRequest, sAddress: response.data.user.sAddress });
        } else if (response.data.user.bAddress) {
          setServiceRequest({ ...serviceRequest, bAddress: response.data.user.bAddress });
        }
        console.log(response.data);
        if (response.data.user.isCreatingEnabled) {
          setIsEnabled(true);
          getAllUsers();
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  function getDataBillingAddress() {
    if (localStorage.getItem(SAVED_B_ADDRESS_KEY) != null) {
      let savedString = localStorage.getItem(SAVED_B_ADDRESS_KEY);
      let saved = JSON.parse(savedString);

      saved = saved.map((address) => {
        return {
          name: address.name,
          attention: address.attention,
          email: address.email,
          phone: address.phone,
          address: `${address.addressLine1}\n${address.addressLine2}\n${address.addressLine3}\n${address.city}\n${address.state}-${address.zip}`,
          key: uuidv4(),
          initValue: address,
        };
      });
      return saved;
    }
    return [];
  }

  function getDataShippingAddress() {
    if (localStorage.getItem(SAVED_S_ADDRESS_KEY) != null) {
      let savedString = localStorage.getItem(SAVED_S_ADDRESS_KEY);
      let saved = JSON.parse(savedString);

      saved = saved.map((address) => {
        return {
          name: address.name,
          attention: address.attention,
          email: address.email,
          phone: address.phone,
          address: `${address.addressLine1}\n${address.addressLine2}\n${address.addressLine3}\n${address.city}\n${address.state}-${address.zip}`,
          key: uuidv4(),
          initValue: address,
        };
      });
      return saved;
    }
    return [];
  }

  const [isFetchingGSTIN, setisFetchingGSTIN] = useState(false);
  const [fetchGSTNo, setfetchGSTNo] = useState("");
  const [addNewUserType, setAddNewUserType] = useState("business");
  const [errorText, setErrorText] = useState(undefined);
  const [users, setUsers] = useState([]);
  const [srUsers, setSRUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenSAddress, setIsModalOpenSAddress] = useState(false);
  const [isModalOpenAddress, setIsModalOpenAddress] = useState(false);
  const sitekey = CAPTCHA_KEY;
  let selectedBAddress = undefined;
  let selectedSAddress = undefined;

  const onRadioChange = (e) => {
    setAddNewUserType(e.target.value);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    if (selectedBAddress != undefined) {
      setServiceRequest({
        ...serviceRequest,
        bAddress: selectedBAddress,
      });
    }
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModalSAddress = () => {
    setIsModalOpenSAddress(true);
  };
  const handleOkSAddress = () => {
    setIsModalOpenSAddress(false);
    if (selectedSAddress != undefined) {
      setServiceRequest({
        ...serviceRequest,
        sAddress: selectedSAddress,
      });
    }
  };
  const handleOkAddress = () => {
    setIsModalOpenAddress(false);
    setTimeout(() => {
      setIsCreating(false);
      window.location.reload();
    }, 1000);
  };
  const handleCancelSAddress = () => {
    setIsModalOpenSAddress(false);
  };

  const handleCancelAddress = () => {
    setIsModalOpenAddress(false);
    setTimeout(() => {
      setIsCreating(false);
      window.location.reload();
    }, 1000);
  };

  function onCheckboxChange(event) {
    setIsChecked(event.target.checked);
  }

  function onDeleteMain(eid) {
    setServiceRequest({
      ...serviceRequest,
      devices: serviceRequest.devices.filter((device) => device.eid !== eid),
    });
  }

  function onDeviceAdd(device) {
    console.log(device);
    setServiceRequest({
      ...serviceRequest,
      devices: [...serviceRequest.devices, device],
    });
  }

  function onSiteVisitChargeChange(charge) {
    console.log("onSiteVisitChargeChange", charge);
    setServiceRequest({
      ...serviceRequest,
      siteVisitCharge: charge,
    });
  }

  async function onCreateSR() {
    let newServiceRequest = serviceRequest;
    if (isChecked) {
      newServiceRequest = { ...newServiceRequest, sAddress: newServiceRequest.bAddress };
    }
    newServiceRequest = { ...newServiceRequest, custInfo };
    console.log(newServiceRequest);
    //let token = await recaptchaRef.current.executeAsync();
    try {
      // if (token == "" || token == undefined || token == null) {
      //   api.error({
      //     message: "Service Request",
      //     description: "reCAPTCHA is not valid",
      //   });
      //   return;
      // }
      setIsCreating(true);
      let result = await axios.post(API_ENDPOINT + "/submit", newServiceRequest);
      console.log(result);
      if (result.data.success) {
        api.success({
          message: "Service Request",
          description: "Successfully created Service Request.",
        });

        let savedBAddresses = localStorage.getItem(SAVED_B_ADDRESS_KEY);
        if (savedBAddresses == null) {
          localStorage.setItem(SAVED_B_ADDRESS_KEY, JSON.stringify([newServiceRequest.bAddress]));
        } else {
          let saved = JSON.parse(localStorage.getItem(SAVED_B_ADDRESS_KEY));
          let existing = false;
          for (const add of saved) {
            let h = hash(add);
            let ch = hash(newServiceRequest.bAddress);
            if (h == ch) {
              existing = true;
              break;
            }
          }
          if (!existing) {
            saved.unshift(newServiceRequest.bAddress);
            localStorage.setItem(SAVED_B_ADDRESS_KEY, JSON.stringify(saved));
          }
        }

        let savedSAddresses = localStorage.getItem(SAVED_S_ADDRESS_KEY);
        if (savedSAddresses == null) {
          localStorage.setItem(SAVED_S_ADDRESS_KEY, JSON.stringify([newServiceRequest.sAddress]));
        } else {
          let saved = JSON.parse(localStorage.getItem(SAVED_S_ADDRESS_KEY));
          let existing = false;
          for (const add of saved) {
            let h = hash(add);
            let ch = hash(newServiceRequest.sAddress);
            if (h == ch) {
              existing = true;
              break;
            }
          }
          if (!existing) {
            saved.unshift(newServiceRequest.sAddress);
            localStorage.setItem(SAVED_S_ADDRESS_KEY, JSON.stringify(saved));
          }
        }

        setIsModalOpenAddress(true);
      } else {
        api.error({
          message: "Service Request",
          description: result.data?.message ?? "Something went wrong. Please try again",
        });
        setIsCreating(false);
        // recaptchaRef.current.reset();
      }
    } catch (err) {
      console.log(err);
      api.error({
        message: "Service Request",
        description: err.response?.data?.message ?? "Failed to create Service Request. Please try again",
        duration: 10,
      });
      setIsCreating(false);
      // recaptchaRef.current.reset();
    }
  }

  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(true);
  const [isCreating, setIsCreating] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [collapsed, setCollapsed] = useState(true);
  //const recaptchaRef = useRef(null);
  const { useToken } = theme;
  const { token } = useToken();
  const [isEnabled, setIsEnabled] = useState(false);
  const [addUserOpen, setAddUserOpen] = useState(false);
  const [addUserConfirmLoading, setAddUserConfirmLoading] = useState(false);

  const [serviceRequest, setServiceRequest] = useState(
    props.serviceRequest ?? {
      bAddress: { country: "India" },
      sAddress: {},
      devices: [],
      contacts: [],
      custInfo: {},
    }
  );

  const [custInfo, setCustInfo] = useState(serviceRequest.custInfo ?? {});

  const [newUser, setNewUser] = useState({
    bAddress: { country: "India" },
    sAddress: {},
  });
  const onRecaptchaChange = (value) => {};

  const genExtra = () => (
    <a
      style={{ color: "#ae9572" }}
      onClick={(event) => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation();
        showModal(true);
      }}
    >
      Select
    </a>
  );

  const genExtraSAddress = () => (
    <a
      style={{ color: "#ae9572" }}
      onClick={(event) => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation();
        showModalSAddress(true);
      }}
    >
      Select
    </a>
  );

  function onEmailIdChange(email) {
    let contacts = serviceRequest.contacts;
    if (!contacts.includes(email) && isValidEmail(email)) {
      contacts.push(email);
    }
    setServiceRequest({ ...serviceRequest, contacts });
  }

  function onEmailDelete(email) {
    let contacts = serviceRequest.contacts.filter((em) => {
      return em !== email;
    });
    setServiceRequest({ ...serviceRequest, contacts });
  }

  const onUserChange = (value) => {
    console.log(`selected ${value}`);
    let user = users.filter((user) => {
      return user._id == value;
    })[0];
    console.log(user);
    setServiceRequest({ ...serviceRequest, bAddress: user.bAddress, sAddress: user.sAddress });
  };

  const onSRUserChange = (value) => {
    setServiceRequest({ ...serviceRequest, createdFor: value });
  };

  const onUserSearch = (value) => {
    console.log("search:", value);
  };

  const handleAddUserOk = async () => {
    let isSomethingWrong = false;
    if (newUser.bAddress == undefined || Object.keys(newUser.bAddress).length == 0) {
      isSomethingWrong = true;
      setErrorText("Billing Address is not valid");
      setTimeout(() => {
        setErrorText(undefined);
        isSomethingWrong = false;
      }, 2000);
    }

    if (isChecked) {
      newUser.sAddress = {
        ...newUser.bAddress,
      };
    }

    if (newUser.sAddress == undefined || Object.keys(newUser.sAddress).length == 0) {
      isSomethingWrong = true;
      setErrorText("Shipping Address is not valid");
      setTimeout(() => {
        setErrorText(undefined);
        isSomethingWrong = false;
      }, 2000);
    }

    console.log(newUser.bAddress);

    let [isValid, msg] = validateAddress(newUser.bAddress, "billing");
    if (!isValid) {
      isSomethingWrong = true;
      setErrorText(msg + " (Billing Address)");
      setTimeout(() => {
        setErrorText(undefined);
        isSomethingWrong = false;
      }, 2000);
      return;
    }

    let [isValid2, msg2] = validateAddress(newUser.sAddress, "shipping");
    console.log(isValid2, msg2);
    if (!isValid2) {
      isSomethingWrong = true;
      setErrorText(msg2 + " (Shipping Address)");
      setTimeout(() => {
        setErrorText(undefined);
        isSomethingWrong = false;
      }, 2000);
      return;
    }

    if (!isSomethingWrong) {
      setAddUserConfirmLoading(true);
      try {
        let response = undefined;
        if (addNewUserType == "business") {
          response = await axios.post(API_ENDPOINT + "/users/registerNewUserToFlow", { ...newUser });
        } else {
          response = await axios.post(API_ENDPOINT + "/users/clients", { ...newUser });
        }
        if (response.data.success) {
          setAddUserOpen(false);
          setAddUserConfirmLoading(false);
          setNewUser({ bAddress: { country: "India" }, sAddress: {} });
          getAllUsers();
        }
      } catch (err) {
        setAddUserConfirmLoading(false);
        setErrorText(err.response.data.message);
        setTimeout(() => {
          setErrorText(undefined);
        }, 2000);
      }
    }
  };
  const handleAddUserCancel = () => {
    setAddUserOpen(false);
    setAddUserConfirmLoading(false);
    setNewUser({ bAddress: { country: "India" }, sAddress: {} });
  };

  useEffect(() => {
    getUserDetails();
    getAllSRUsers();
    console.log("CSR useEffect called", serviceRequest);
    if (props.serviceRequest) {
      setServiceRequest(props.serviceRequest);
      setCustInfo(props.serviceRequest.custInfo ?? {});
    }
  }, [props]);

  if (isEnabled) {
    console.log("CSR redrawing");

    return (
      <Fragment>
        {contextHolder}
        {/* <ArrowLeftOutlined
          style={{ color: "white" }}
          onClick={() => {
            navigate("/");
          }}
        /> */}
        <Modal title="Add New User" open={addUserOpen} onOk={handleAddUserOk} confirmLoading={addUserConfirmLoading} onCancel={handleAddUserCancel} width={1000}>
          <Row style={{ marginBottom: 20, marginTop: 20 }} align={"middle"} justify={"center"}>
            <Radio.Group value={addNewUserType} onChange={onRadioChange}>
              <Radio value={"business"}>Business</Radio>
              <Radio value={"customer"}>Customer</Radio>
            </Radio.Group>
          </Row>
          <Row>
            {addNewUserType == "business" && (
              <Input.Search
                placeholder="Type GSTIN Numer and Press Enter"
                value={fetchGSTNo}
                onChange={(event) => {
                  setfetchGSTNo(event.target.value);
                }}
                onKeyDown={(event) => {
                  if (event.key == "Enter") {
                    fetchGST();
                  }
                }}
                loading={isFetchingGSTIN}
              />
            )}
          </Row>
          <Row gutter={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
            <Col span={{ sm: 24, md: 12, xs: 24 }} style={{ padding: 10 }} flex={1}>
              <Collapse>
                {/* extra={genExtra()} */}
                <Panel header="Billing Address" key="1">
                  <Address type="billing" address={newUser.bAddress} onAddressChange={onAddNewUserAddressChange} />
                </Panel>
              </Collapse>
            </Col>
            <Col span={{ sm: 24, md: 12 }} style={{ padding: 10 }} flex={1}>
              <Checkbox checked={isChecked} style={{ marginBottom: 10 }} onChange={onCheckboxChange}>
                Shipping Address is same as Billing Address
              </Checkbox>
              {!isChecked && (
                <Collapse>
                  {/* extra={genExtraSAddress()} */}
                  <Panel header="Shipping Address" key="2">
                    <Address type="shipping" address={newUser.sAddress} onAddressChange={onAddNewUserSAddressChange} />
                  </Panel>
                </Collapse>
              )}
            </Col>
          </Row>
          {errorText && <Alert message={errorText} type="error" />}
        </Modal>
        {/* <div style={{ textAlign: "center" }}>
          <h3 style={{ color: token.colorText }}>Create Service Request :</h3>
        </div> */}
        <Card
          className="glass"
          title="Addresses"
          style={{
            width: "100%",
          }}
        >
          <Row style={{ marginBottom: 20 }}>
            <Col span={23}></Col>
            <Col span={1}>
              <Button
                type="primary"
                icon={<PlusCircleOutlined />}
                size={"large"}
                onClick={() => {
                  setAddUserOpen(true);
                }}
              />
            </Col>
          </Row>
          <Select
            style={{ width: "100%" }}
            showSearch
            placeholder="Select Business / End Customer"
            optionFilterProp="label"
            onChange={onUserChange}
            onSearch={onUserSearch}
            options={users.map((user) => {
              return {
                value: user._id,
                label: user.name + "/" + user.bAddress.email + "/" + user.bAddress.phone + (user.bAddress.GSTIN == undefined ? "" : "/" + user.bAddress.GSTIN),
              };
            })}
          />

          <Row gutter={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
            <Col span={{ sm: 24, md: 12, xs: 24 }} style={{ padding: 10 }} flex={1}>
              <Collapse>
                {/* extra={genExtra()} */}
                <Panel header="Billing Address" key="1">
                  <Address type="billing" address={serviceRequest.bAddress} onAddressChange={onAddressChange} disabled="true" />
                </Panel>
              </Collapse>
            </Col>
            <Col span={{ sm: 24, md: 12 }} style={{ padding: 10 }} flex={1}>
              <Checkbox checked={isChecked} style={{ marginBottom: 10 }} onChange={onCheckboxChange}>
                Shipping Address is same as Billing Address
              </Checkbox>
              {!isChecked && (
                <Collapse>
                  {/* extra={genExtraSAddress()} */}
                  <Panel header="Shipping Address" key="2">
                    <Address type="shipping" address={serviceRequest.sAddress} onAddressChange={onSAddressChange} />
                  </Panel>
                </Collapse>
              )}
            </Col>
          </Row>
          <Row style={{ marginTop: 20, marginBottom: 20 }}>
            <b>Who is this Service Request for ?</b>
          </Row>
          <Select
            style={{ width: "100%" }}
            showSearch
            mode="multiple"
            allowClear
            placeholder="Select Portal Signed Up Users"
            optionFilterProp="label"
            onChange={onSRUserChange}
            options={srUsers.map((user) => {
              return {
                value: user._id,
                label: user.name + "/" + user.email + "/" + user.companyName,
              };
            })}
          />
        </Card>
        <Devices onDeviceAddMain={onDeviceAdd} onDeleteMain={onDeleteMain} devices={serviceRequest.devices} />
        <Row>
          <Col span={8} style={{ padding: 20 }}>
            <AdditionalCharges onSiteVisitChargeChangeMain={onSiteVisitChargeChange} siteVisitCharge={serviceRequest.siteVisitCharge} />
          </Col>
          <Col span={8} style={{ padding: 10 }}>
            <ContactNotifier
              onEmailIdChange={(email) => {
                onEmailIdChange(email);
              }}
              onEmailDelete={(email) => {
                onEmailDelete(email);
              }}
              contacts={serviceRequest.contacts}
            />
          </Col>
          <Col span={8} style={{ padding: 10 }}>
            <Card title="Customer Information (Optional)" className="glass">
              <Col>
                <Alert message="You can use below information later on to search this Service Request in case you remember neither the SRN nor the eIDs associated with it." type="info" />
                <Input placeholder="Name" style={{ marginTop: 10 }} onChange={(event) => setCustInfo({ ...custInfo, name: event.target.value })} value={custInfo.name} />
                <Input placeholder="Email" style={{ marginTop: 10 }} onChange={(event) => setCustInfo({ ...custInfo, email: event.target.value })} value={custInfo.email} />
                <Input placeholder="Mobile Number" style={{ marginTop: 10 }} onChange={(event) => setCustInfo({ ...custInfo, phone: event.target.value })} value={custInfo.phone} />
                <Input placeholder="Additional Information" style={{ marginTop: 10 }} onChange={(event) => setCustInfo({ ...custInfo, comment: event.target.value })} value={custInfo.comment} />
              </Col>
            </Card>
          </Col>
        </Row>
        {/* <Card title="eGlu Service Center Address" style={{ width: "100%", marginTop: 20 }} className="glass">
          <Col span={24}>
            <span>Ship your devices to following Address : </span>
            <br />
            <br />
            <strong style={{ fontSize: "16px" }}>eGlu Smart Homes</strong>
            <br />
            <span>#301, Regent Prime</span> <br />
            <span>Satya Sai Layout, Whitefield Main Road</span> <br />
            <span>Above Gold Gym</span>
            <br />
            <span>Bangalore - 560066</span> <br />
            <span>Karnataka</span> <br />
          </Col>
        </Card> */}
        {/* <div style={{ textAlign: "center" }}>
          <ReCAPTCHA style={{ marginTop: 30 }} ref={recaptchaRef} sitekey={sitekey} size="invisible" onChange={onRecaptchaChange} />
        </div> */}
        <Modal title="eGlu Service Center Address" open={isModalOpenAddress} onOk={handleOkAddress} onCancel={handleCancelAddress}>
          <Col span={24}>
            <span>Ship your devices to following Address : </span>
            <br />
            <br />
            <strong style={{ fontSize: "16px" }}>eGlu Smart Homes</strong>
            <br />
            <span>#301, Regent Prime</span> <br />
            <span>Satya Sai Layout, Whitefield Main Road</span> <br />
            <span>Above Gold Gym</span>
            <br />
            <span>Bangalore - 560066</span> <br />
            <span>Karnataka</span> <br />
          </Col>
        </Modal>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={24}>
            <Button type="primary" loading={isCreating} style={{ backgroundColor: "#ae9572", marginTop: 30, color: "black", position: "fixed", bottom: "10px", right: "40px", left: "40px" }} onClick={onCreateSR}>
              Create Service Request
            </Button>
          </Col>
        </Row>
        <Modal title="Select Existing Billing Address" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} bodyStyle={{ overflowX: "scroll" }}>
          <Table
            rowSelection={{
              type: "radio",
              ...rowSelection,
            }}
            columns={addressColumns}
            dataSource={getDataBillingAddress()}
          />
        </Modal>
        <Modal title="Select Existing Shipping Address" open={isModalOpenSAddress} onOk={handleOkSAddress} onCancel={handleCancelSAddress} bodyStyle={{ overflowX: "scroll" }}>
          <Table
            rowSelection={{
              type: "radio",
              ...rowSelectionSAddress,
            }}
            columns={addressColumns}
            dataSource={getDataShippingAddress()}
          />
        </Modal>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Alert message="eGlu Service" description="Thank you for using eGlu Service Portal. To create Service Request please contact eGlu Support at support@myeglu.com / +91-80-68494214 / 1800 202 6995 (Toll Free)" type="info" showIcon />
      </Fragment>
    );
  }
};

export default CreateServiceRequest;
