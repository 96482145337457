import { Col, Row } from "antd";
import { Input, Button, Card, Steps, notification, theme, Alert, Table, List, Descriptions, Collapse } from "antd";
import CreateServiceRequest from "./CreateServiceRequest";
import { useState, useLayoutEffect } from "react";
import axios from "axios";
import { API_ENDPOINT } from "../api/endPoint";
import Lottie from "react-lottie";
import * as animationData from "../srs.json";
import { useSizeComponents } from "../utils/hooks";
import Address from "./Address";

const Search = () => {
  let [srn, setSRN] = useState("");
  let [status, setStatus] = useState([]);
  let [item, setItem] = useState(undefined);
  let [loading, setLoading] = useState(false);
  let [api, contextHolder] = notification.useNotification();
  const { useToken } = theme;
  const { token } = useToken();
  const { Panel } = Collapse;
  const columns = [
    {
      title: "eID",
      dataIndex: "eid",
      key: "eid",
    },
    {
      title: "Problem",
      dataIndex: "problem",
      key: "problem",
    },
    {
      title: "Description",
      dataIndex: "desc",
      key: "desc",
    },
    {
      title: "Ticket No",
      dataIndex: "ticketNo",
      key: "ticketNo",
    },
  ];

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid meet",
    },
  };

  function onSRNChange(event) {
    setSRN(event.target.value);
  }

  async function getDetails() {
    try {
      if (srn == "") {
        api.error({
          message: "Service Request",
          description: "SRN can not be empty",
        });
        return;
      }
      srn = srn.toUpperCase();
      setLoading(true);
      let response = await axios.get(API_ENDPOINT + "/status/" + srn);
      if (response.data.success && response.data.status && response.data.status.statuses && response.data.status.statuses.length > 0) {
        setItem(response.data.item);
        setStatus(response.data.status);
        setLoading(false);
      } else {
        setLoading(false);
        api.error({
          message: "Service Request",
          description: "Failed to get details of the Service. Please make sure you have provided the corrent SRN.",
        });
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      api.error({
        message: "Service Request",
        description: "Failed to get details of the Service. Please make sure you have provided the corrent SRN.",
      });
    }
  }

  const [width, height] = useSizeComponents();
  const scaleLottie = 0.35;
  const xMidYMid = 0.5;
  const sizeComponent = {
    width: width * scaleLottie,
    height: width * scaleLottie * xMidYMid,
  };

  return (
    <Row
      gutter={{
        xs: 8,
        sm: 8,
        md: 8,
        lg: 8,
      }}
    >
      <Col span={24}>
        <div style={{ textAlign: "center", width: "100%" }}>
          <Lottie options={defaultOptions} {...sizeComponent} />
        </div>
      </Col>
      <Col span={24}>
        {contextHolder}
        <div style={{ textAlign: "center", fontSize: 15, marginBottom: 20 }}>
          <span style={{ color: token.colorText }}>Enter your existing Service Request Number to check the current status</span>
        </div>
        <Input placeholder="Enter Service Request Number" style={{ marginBottom: "10px", textTransform: "uppercase" }} type="text" onChange={onSRNChange} />
        <Button type="primary" loading={loading} style={{ width: "100%", backgroundColor: "#ae9572", color: "black" }} onClick={getDetails}>
          Check Status
        </Button>
        {/* <div style={{ marginTop: "20px", backgroundImage: "linear-gradient(rgba(174,149,114,0.2),rgba(255,255,255,0.2))", padding: 20, borderRadius: 20 }}>
          {
            <span style={{ color: token.colorText }}>
              <i>
                Check Service Request Status directly on WhatsApp : Send <b>"SRS:YOUR_SRN"</b> to{" "}
                <b>
                  <a style={{ color: token.colorText, textDecoration: "underline" }} target="_blank" href="https://api.whatsapp.com/send?phone=+918867531314&text=SRS:">
                    +918867531314
                  </a>
                </b>{" "}
                . To check status of all of your Service Requests, send <b>"Service Request Status"</b> to the{" "}
                <b>
                  <a style={{ color: token.colorText, textDecoration: "underline" }} target="_blank" href="https://api.whatsapp.com/send?phone=+918867531314&text=Service+Request+Status">
                    same number
                  </a>
                </b>{" "}
                .
              </i>
            </span>
          }
        </div> */}
        {status.statuses && status.statuses.length > 0 && (
          <Card title={`Status for Service Request : ${srn.toUpperCase()}`} style={{ marginTop: 20, backgroundColor: "transparent" }}>
            <Col span={24}>
              <Collapse style={{ marginBottom: 15 }} defaultActiveKey={1}>
                <Panel header="Status" key={1}>
                  {status.statuses && status.statuses.length > 0 && (
                    <Card title={`Status for Service Request : ${item.srn.toUpperCase()}`} style={{ marginTop: 20, backgroundColor: "transparent" }}>
                      <Steps items={status.statuses} status="finish" current={status.current} direction="vertical" />
                    </Card>
                  )}
                </Panel>
              </Collapse>
              <Collapse>
                <Panel header="Devices" key="1">
                  <Table dataSource={item.devices} columns={columns} style={{ overflow: "scroll" }} />
                </Panel>
              </Collapse>
              {item.custInfo != undefined && (
                <Collapse style={{ marginTop: 10 }}>
                  <Panel header="Customer Information" key="1">
                    <Descriptions title="User Info">
                      {item.custInfo.name && <Descriptions.Item label="Name">{item.custInfo.name}</Descriptions.Item>}
                      {item.custInfo.email && <Descriptions.Item label="Email">{item.custInfo.email}</Descriptions.Item>}
                      {item.custInfo.phone && <Descriptions.Item label="Mobile">{item.custInfo.phone}</Descriptions.Item>}
                      {item.custInfo.comment && <Descriptions.Item label="Additional Information">{item.custInfo.comment}</Descriptions.Item>}
                    </Descriptions>
                  </Panel>
                </Collapse>
              )}
              {/* <Row gutter={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
                <Col span={{ sm: 24, md: 12, xs: 24 }} style={{ padding: 10 }} flex={1}>
                  <Collapse>
                    <Panel header="Billing Address" key="1">
                      <Address type="billing" address={item.bAddress} onAddressChange={() => {}} />
                    </Panel>
                  </Collapse>
                </Col>
                <Col span={{ sm: 24, md: 12 }} style={{ padding: 10 }} flex={1}>
                  <Collapse>
                    <Panel header="Shipping Address" key="2">
                      <Address type="shipping" address={item.sAddress} onAddressChange={() => {}} />
                    </Panel>
                  </Collapse>
                </Col>
              </Row> */}
            </Col>
            {/* <Steps items={status.statuses} status="finish" current={status.current} direction="vertical" /> */}
          </Card>
        )}
      </Col>
    </Row>
  );
};
export default Search;
